import { isNotNullOrEmpty } from '@globals';
import { Coding } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';
import { Badge, SeverityLevel } from '@layout';

export function joinValues(values: (string | undefined)[] | undefined, delimiter = ' '): string | undefined {
  const result: string | undefined = values
    ?.filter((value: string | undefined) => isNotNullOrEmpty(value))
    .join(delimiter);

  return result || undefined;
}

// ! TODO: This implementation should be improved!
export function findBadge(severityLevel: SeverityLevel): Badge {
  switch (severityLevel) {
    case SeverityLevel.Fatal:
      return { code: severityLevel, color: '#dc3646', icon: 'hand-palm', textColor: '#fff' };
    case SeverityLevel.Severe:
      return { code: severityLevel, color: '#dc3646', icon: 'warning', textColor: '#fff' };
    default:
      return { code: severityLevel, color: '#f5f5f5', textColor: '#000' };
  }
}

export function getCodingsDetails(codings: Coding[] | undefined): string | undefined {
  const texts = codings?.map((coding) => {
    if (coding.system === CodeSystems.R3_V3_NULL_FLAVOR) {
      return undefined;
    }

    const label = CodeSystems.getLabel(coding.system) ?? coding.system;
    const codeValue = coding.code;
    const text = joinValues([label, codeValue], ' ');

    return text;
  });

  return joinValues(texts, ', ');
}
