import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { environment } from '@environments/environment';
import {
  ApiValidatorBodyTransactionApple,
  ApiValidatorBodyTransactionGoogle,
  AppleTransaction,
  CapacitorUtils,
  createTransactionActionPayload,
  PaywallPlan,
  Platform,
  PurchaseData,
  SubscriptionPurchase,
  ValidateReceiptPayload,
} from '@globals';
import { Store } from '@ngrx/store';
import { IAP_ACTIONS, SUBSCRIPTION_ACTIONS } from '@store/app';
import { firstValueFrom, Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  platformStore!: CdvPurchase.Store;

  /**
   * Retrieves the monthly subscription offer for the current platform (iOS or Android).
   *
   */
  get monthlyOffer(): CdvPurchase.Offer | undefined {
    if (CapacitorUtils.isIOS()) {
      const product = this.platformStore.get(
        environment.products.ios.pro.monthlyProdId,
        CdvPurchase.Platform.APPLE_APPSTORE,
      );

      if (!product) {
        // throw new Error(`Product '${environment.products.ios.pro.monthlyProdId}' not available`);
        return;
      }

      const offer = product.getOffer();

      if (!offer) {
        // throw new Error(`Offer for product '${environment.products.ios.pro.monthlyProdId}' not available`);
        return;
      }

      return offer;
    } else if (CapacitorUtils.isAndroid()) {
      const product = this.platformStore.get(environment.products.android.pro.prodId, CdvPurchase.Platform.GOOGLE_PLAY);

      if (!product) {
        // throw new Error(`Product '${environment.products.android.pro.prodId}' not available`);
        return;
      }

      const offer = product.getOffer(environment.products.android.pro.monthlyPlan);

      if (!offer) {
        // throw new Error(
        //   `Offer '${environment.products.android.pro.monthlyPlan}' for product '${environment.products.android.pro.prodId}' not available`,
        // );
        return;
      }

      return offer;
    }

    // throw new Error('Platform not supported');
    return;
  }

  /**
   * Retrieves the annual subscription offer for the current platform (iOS or Android).
   *
   */
  get annualOffer(): CdvPurchase.Offer | undefined {
    if (CapacitorUtils.isIOS()) {
      const product = this.platformStore.get(
        environment.products.ios.pro.annualProdId,
        CdvPurchase.Platform.APPLE_APPSTORE,
      );

      if (!product) {
        // throw new Error(`Product '${environment.products.ios.pro.annualProdId}' not available`);
        return;
      }

      const offer = product.getOffer();

      if (!offer) {
        // throw new Error(`Offer for product '${environment.products.ios.pro.annualProdId}' not available`);
        return;
      }

      return offer;
    } else if (CapacitorUtils.isAndroid()) {
      const product = this.platformStore.get(environment.products.android.pro.prodId, CdvPurchase.Platform.GOOGLE_PLAY);

      if (!product) {
        // throw new Error(`Product '${environment.products.android.pro.prodId}' not available`);
        return;
      }

      const offer = product.getOffer(environment.products.android.pro.annualPlan);

      if (!offer) {
        // throw new Error(`Offer for product '${environment.products.android.pro.prodId}' not available`);
        return;
      }

      return offer;
    }

    // throw new Error('Platform not supported');
    return;
  }

  constructor(
    private readonly store: Store,
    private readonly apiService: ApiService,
  ) {}

  init(): void {
    this.platformStore = CdvPurchase.store;

    // Only for debugging
    // const packageName = await CapacitorUtils.packageId();
    // if (packageName.includes('dev')) {
    //   this.platformStore.verbosity = CdvPurchase.LogLevel.DEBUG;
    // }

    this.platformStore.verbosity = CdvPurchase.LogLevel.DEBUG;

    this.registerValidator();
    this.registerProducts();
    this.registerListeners();

    this.createStore();
  }

  /**
   * Places an order for a subscription based on the provided subscription type.
   *
   */
  order(plan: PaywallPlan): void {
    let offer: CdvPurchase.Offer | undefined = undefined;

    if (CapacitorUtils.isIOS()) {
      const prodId: string =
        plan === 'annual' ? environment.products.ios.pro.annualProdId : environment.products.ios.pro.monthlyProdId;

      const product: CdvPurchase.Product | undefined = this.platformStore.get(
        prodId,
        CdvPurchase.Platform.APPLE_APPSTORE,
      );

      if (!product) {
        // throw new Error(`Product '${prodId}' not available`);
        return;
      }

      offer = product.getOffer();

      if (!offer) {
        // throw new Error(`Offer in product '${prodId}' not available`);
        return;
      }
    } else if (CapacitorUtils.isAndroid()) {
      const prodId: string = environment.products.android.pro.prodId;
      const offerId: string =
        plan === 'annual' ? environment.products.android.pro.annualPlan : environment.products.android.pro.monthlyPlan;

      const product = this.platformStore.get(prodId, CdvPurchase.Platform.GOOGLE_PLAY);

      if (!product) {
        // throw new Error(`Product '${prodId}' not available`);
        return;
      }

      offer = product.getOffer(offerId);

      if (!offer) {
        // throw new Error(`Offer '${offerId}' in product '${prodId}' not available`);
        return;
      }
    }

    const subscription = {
      plan,
      productId: offer!.productId,
      offerId: offer!.id,
    };

    this.store.dispatch(
      IAP_ACTIONS.subscriptionPurchaseStart({
        subscription,
      }),
    );

    offer!.order().then((error: CdvPurchase.IError | undefined) => {
      if (error) {
        this.store.dispatch(
          IAP_ACTIONS.subscriptionPurchaseFailure({
            subscription: {
              error,
              ...subscription,
            },
          }),
        );

        return;
      }

      this.store.dispatch(IAP_ACTIONS.subscriptionPurchaseSuccess({ subscription }));
    });
  }

  /**
   * Restores the purchases by updating the platform store and dispatching the restore purchases action.
   *
   */
  restore(): void {
    this.store.dispatch(IAP_ACTIONS.restorePurchasesStart());

    this.platformStore.restorePurchases().then((error: CdvPurchase.IError | undefined) => {
      this.restoreCallback(error);
    });
  }

  /**
   * Initializes the platform store with available platforms.
   *
   * This method registers the store by initializing it with the available platforms
   * (Google Play and Apple App Store). It then updates the platform store and restores
   * purchases. Restore purchases method exists to cover an Apple AppStore requirement.
   *
   */
  private createStore(): void {
    this.store.dispatch(IAP_ACTIONS.pluginLoadStart());

    const availablePlatforms: (CdvPurchase.Platform | CdvPurchase.PlatformWithOptions)[] = [
      CdvPurchase.Platform.GOOGLE_PLAY,
      CdvPurchase.Platform.APPLE_APPSTORE,
    ];

    this.platformStore.initialize(availablePlatforms).then((errors: CdvPurchase.IError[]) => {
      if (errors.length > 0) {
        this.store.dispatch(IAP_ACTIONS.pluginLoadFailure({ errors }));

        return;
      }

      this.store.dispatch(IAP_ACTIONS.pluginLoadSuccess());

      this.platformStore.update().then(() => {
        // ! TODO: Check if this is really necessary for iOS
        // this.store.dispatch(SUBSCRIPTION_STORE_ACTIONS.restorePurchasesStarted());
        // this.platformStore.restorePurchases().then((error: CdvPurchase.IError | undefined) => {
        //   this.restoreCallback(error);
        // });
      });
    });
  }

  /**
   * The receipt validation service.
   *
   */
  private registerValidator(): void {
    this.platformStore.validator = async (receipt, callback) => {
      const packageName: string = await CapacitorUtils.packageId();

      let payload!: ValidateReceiptPayload;

      if (CapacitorUtils.isAndroid()) {
        const transaction = <ApiValidatorBodyTransactionGoogle>receipt.transaction;

        payload = {
          packageName,
          subscriptionId: receipt.id!,
          purchaseToken: transaction.purchaseToken,
        };
      } else if (CapacitorUtils.isIOS()) {
        const transaction = <ApiValidatorBodyTransactionApple>receipt.transaction;

        payload = {
          receipt: transaction.appStoreReceipt,
        };
      }

      const nativePlatform: string = Capacitor.getPlatform();
      const source: Observable<HttpResponse<PurchaseData>> = this.apiService.verifyReceipt(payload, nativePlatform);
      const response: HttpResponse<PurchaseData> = await firstValueFrom(source);
      const purchaseData: PurchaseData | null = response.body;

      if (purchaseData?.transaction) {
        if (CapacitorUtils.isIOS()) {
          const transaction: AppleTransaction | undefined = purchaseData.transaction as AppleTransaction;

          if (transaction) {
            callback({
              ok: true,
              data: {
                id: transaction.product_id,
                latest_receipt: true,
                transaction: {
                  type: 'ios-appstore',
                  ...transaction,
                },
                date: new Date().toISOString(),
              },
            });
          }
          return;
        } else if (CapacitorUtils.isAndroid()) {
          const transaction: SubscriptionPurchase | undefined = purchaseData.transaction as SubscriptionPurchase;

          if (receipt) {
            callback({
              ok: true,
              data: {
                id: environment.products.android.pro.prodId,
                latest_receipt: true,
                transaction: {
                  type: 'android-playstore',
                  ...transaction,
                },
                date: new Date().toISOString(),
              },
            });

            return;
          }
        }
      }

      callback({
        ok: false,
      });
    };
  }

  /**
   * Registers the in-app purchase products for both Android and iOS platforms.
   *
   * - For Android, it registers a single paid subscription product. The product has two
   *  plans (offers): monthly and annual.
   * - For iOS, it registers two paid subscription products (monthly and annual).
   *
   * To update the product details, update the environment file.
   *
   */
  private registerProducts(): void {
    const androidProducts = [
      {
        id: environment.products.android.pro.prodId,
        type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
        platform: CdvPurchase.Platform.GOOGLE_PLAY,
      },
    ];

    const iosProducts = [
      {
        id: environment.products.ios.pro.monthlyProdId,
        type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
        platform: CdvPurchase.Platform.APPLE_APPSTORE,
      },
      {
        id: environment.products.ios.pro.annualProdId,
        type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
        platform: CdvPurchase.Platform.APPLE_APPSTORE,
      },
    ];

    this.platformStore.register([
      ...(CapacitorUtils.isAndroid() ? androidProducts : []),
      ...(CapacitorUtils.isIOS() ? iosProducts : []),
    ]);
  }

  private registerListeners(): void {
    this.platformStore
      .when()
      // ! TODO: The initiated event is not being called.
      .initiated(async (transaction: CdvPurchase.Transaction) => {
        this.store.dispatch(
          IAP_ACTIONS.transactionStart({
            transaction: createTransactionActionPayload(transaction),
          }),
        );
      })
      .pending(async (transaction: CdvPurchase.Transaction) => {
        this.store.dispatch(
          IAP_ACTIONS.transactionPending({
            transaction: createTransactionActionPayload(transaction),
          }),
        );
      })
      .approved(async (transaction: CdvPurchase.Transaction) => {
        const packageName = await CapacitorUtils.packageId();
        if (transaction.products && transaction.products?.[0].id === packageName) {
          transaction.finish();
          return;
        }

        this.store.dispatch(
          IAP_ACTIONS.transactionApproved({
            transaction: createTransactionActionPayload(transaction),
          }),
        );

        if (transaction.state === 'approved') {
          this.store.dispatch(
            IAP_ACTIONS.receiptValidationStart({
              transaction: createTransactionActionPayload(transaction),
            }),
          );
          transaction.verify();
        }
      })
      .verified((verifiedReceipt: CdvPurchase.VerifiedReceipt) => {
        const transaction = verifiedReceipt.sourceReceipt.lastTransaction();

        this.store.dispatch(
          IAP_ACTIONS.receiptValidationSuccess({
            receipt: {
              productId: verifiedReceipt.id,
              validationDate: verifiedReceipt.validationDate,
              transaction: {
                transactionId: transaction?.transactionId,
                transactionState: transaction?.state,
              },
            },
          }),
        );

        this.updateState(verifiedReceipt.nativeTransactions);

        verifiedReceipt.finish();
      })
      .unverified((receipt: CdvPurchase.UnverifiedReceipt) => {
        const transaction = receipt.receipt.lastTransaction();

        this.store.dispatch(
          IAP_ACTIONS.receiptValidationFailure({
            receipt: {
              transactionId: transaction?.transactionId,
              transactionState: transaction?.state,
              productId: transaction?.products.map((product) => product.id).join(','),
            },
            error: {
              errorMessage: receipt.payload.message,
              errorCode: receipt.payload.code,
              errorStatus: receipt.payload.status,
            },
          }),
        );
      })
      .finished(async (transaction: CdvPurchase.Transaction) => {
        const packageName = await CapacitorUtils.packageId();
        if (transaction.products && transaction.products?.[0].id === packageName) {
          return;
        }

        this.store.dispatch(
          IAP_ACTIONS.transactionFinished({
            transaction: createTransactionActionPayload(transaction),
          }),
        );
      });

    this.platformStore.error((error: any) => {
      // ! TODO: Log errors
      // console.error('Store error:', error);
    });
  }

  private updateState(transactions: CdvPurchase.Validator.Response.NativeTransaction[]): void {
    transactions.forEach((transaction: CdvPurchase.Validator.Response.NativeTransaction) => {
      let isExpired = false;
      let purchaseDate: string | undefined | null;

      if (CapacitorUtils.isIOS()) {
        const appleTransaction = transaction as AppleTransaction;
        isExpired = this.isSubscriptionExpired(appleTransaction.expires_date_ms);
        purchaseDate = appleTransaction.purchase_date_ms;
      } else if (CapacitorUtils.isAndroid()) {
        const androidTransaction = transaction as SubscriptionPurchase;
        isExpired = this.isSubscriptionExpired(androidTransaction.expiryTimeMillis);
        purchaseDate = androidTransaction.startTimeMillis;
      }

      this.store.dispatch(
        SUBSCRIPTION_ACTIONS.updateFinished({
          subscription: {
            active: !isExpired,
            purchaseDate,
            platform: Capacitor.getPlatform() as Platform,
          },
        }),
      );
    });
  }

  private isSubscriptionExpired(expiryTime: number | string | null | undefined): boolean {
    return expiryTime ? Number(expiryTime) < Date.now() : false;
  }

  private restoreCallback(error: CdvPurchase.IError | undefined): void {
    if (error) {
      this.store.dispatch(IAP_ACTIONS.restorePurchasesFailure({ error }));
      return;
    }

    this.store.dispatch(IAP_ACTIONS.restorePurchasesSuccess());
  }
}
