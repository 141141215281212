import { createSelector } from '@ngrx/store';
import { LoadingState } from '@store';
import { selectIsAuthenticated } from '../../app/selectors/app.selector';
import { DigiMeState } from '../digi-me.reducer';
import { selectDigiMeState } from './digi-me.selectors';

export const selectShowNotificationsAuthenticated = createSelector(
  selectDigiMeState,
  selectIsAuthenticated,
  (state: DigiMeState, authenticated: boolean) => {
    return (state.notification.data?.requiresLogin && authenticated) || !state.notification.data?.requiresLogin;
  },
);

export const selectShowNotificationsModal = createSelector(
  selectDigiMeState,
  (state: DigiMeState) => state.notification.data?.type === 'modal' && state.notification.data?.name !== undefined,
);

export const selectShowNotificationsBanner = createSelector(
  selectDigiMeState,
  (state: DigiMeState) => state.notification.data?.type === 'banner' && state.notification.data?.name !== undefined,
);

export const selectNotificationLoaded = createSelector(
  selectDigiMeState,
  (state: DigiMeState) => state.notification.callState == LoadingState.loaded,
);

export const selectNotificationDismissed = createSelector(
  selectDigiMeState,
  (state: DigiMeState) => state.notificationDismissed,
);

export const selectShowNotifications = createSelector(
  selectShowNotificationsBanner,
  selectShowNotificationsAuthenticated,
  selectNotificationLoaded,
  selectNotificationDismissed,
  (banner: boolean, authenticated: boolean, loaded: boolean, dismissed: boolean) => {
    return banner && authenticated && loaded && !dismissed;
  },
);

export const selectNotification = createSelector(selectDigiMeState, (state: DigiMeState) => state.notification.data);
