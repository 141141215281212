import { DeviceInfo } from '@capacitor/device';
import { ErrorCodes, SubscriptionPayload, SubscriptionPurchaseStatus, VerifyReceiptStatus } from '@globals';
import { createReducer, on } from '@ngrx/store';
import {
  APP_ACTIONS,
  DEMO_MODE_ACTIONS,
  DEVICE_INFO_ACTIONS,
  ERROR_UI_ACTIONS,
  IAP_ACTIONS,
  PAYWALL_VIEW_ACTIONS,
  SUBSCRIPTION_ACTIONS,
  TOAST_ACTIONS,
} from './actions/';

export interface AppState {
  isAuthenticated: boolean;
  error: {
    data: any;
  };
  showingErrorModal: boolean;
  securityPromptModal: boolean;
  hasSessionTimedOut: boolean;
  modal: string | null;
  toasts: ToastState[];
  demoModeModal: boolean;
  subscriptionModal: boolean;
  shareProviders: boolean;
  deviceInfo: DeviceInfo | null;
  iap: {
    subscription: SubscriptionPayload | null;
    storeReady: boolean;
    verifyReceiptStatus: VerifyReceiptStatus;
    restoringPurchases: boolean | undefined;
    subscriptionPurchaseStatus: SubscriptionPurchaseStatus;
  };
}

export interface ToastState {
  message: string;
  typeMessage: 'error' | 'info';
}

export const initialState: AppState = {
  isAuthenticated: false,
  error: {
    data: null,
  },
  showingErrorModal: false,
  securityPromptModal: false,
  hasSessionTimedOut: false,
  modal: null,
  toasts: [],
  demoModeModal: false,
  subscriptionModal: false,
  shareProviders: false,
  deviceInfo: null,
  iap: {
    subscription: {
      active: false,
      purchaseDate: undefined,
      platform: undefined,
    },
    storeReady: false,
    verifyReceiptStatus: 'Initial',
    restoringPurchases: undefined,
    subscriptionPurchaseStatus: 'Initial',
  },
};

export const reducer = createReducer(
  initialState,
  on(APP_ACTIONS.authenticated, (state): AppState => {
    return { ...state, isAuthenticated: true };
  }),
  on(APP_ACTIONS.timeoutLogout, (state): AppState => {
    return { ...state, isAuthenticated: false };
  }),
  on(APP_ACTIONS.unauthenticated, (state): AppState => {
    return { ...state, isAuthenticated: false };
  }),
  on(ERROR_UI_ACTIONS.showErrorMessage, (state, action): AppState => {
    return {
      ...state,
      error: {
        ...state.error,
        data: action.code === ErrorCodes.userCancel ? undefined : action.code,
      },
      showingErrorModal: true,
    };
  }),
  on(ERROR_UI_ACTIONS.resetErrorMessage, (_state): AppState => {
    return { ..._state, showingErrorModal: false, error: { data: null } };
  }),
  on(APP_ACTIONS.showSecurityPromptModal, (_state, action): AppState => {
    return { ..._state, securityPromptModal: action.show };
  }),
  on(APP_ACTIONS.showShareProvidersModal, (_state, action): AppState => {
    return { ..._state, shareProviders: action.show };
  }),
  on(APP_ACTIONS.timeoutLogout, (): AppState => {
    return { ...initialState, hasSessionTimedOut: true };
  }),
  on(TOAST_ACTIONS.showToast, (state, action): AppState => {
    return { ...state, toasts: [...state.toasts, action] };
  }),
  on(TOAST_ACTIONS.hideToast, (state, action): AppState => {
    return { ...state, toasts: state.toasts.filter((t) => t.message !== action.message) };
  }),
  on(DEMO_MODE_ACTIONS.showDemoModeModal, (state): AppState => {
    return { ...state, demoModeModal: true };
  }),
  on(DEMO_MODE_ACTIONS.resetDemoModeModal, (state): AppState => {
    return { ...state, demoModeModal: false };
  }),
  on(DEVICE_INFO_ACTIONS.loadSucceeded, (state, action): AppState => {
    return { ...state, deviceInfo: action.info };
  }),

  on(PAYWALL_VIEW_ACTIONS.open, (state): AppState => {
    return { ...state, subscriptionModal: true };
  }),
  on(PAYWALL_VIEW_ACTIONS.closed, (state): AppState => {
    return { ...state, subscriptionModal: false };
  }),

  on(SUBSCRIPTION_ACTIONS.subscriptionLoadFinished, SUBSCRIPTION_ACTIONS.updateFinished, (state, action): AppState => {
    const { ...payload } = action;

    return {
      ...state,
      iap: {
        ...state.iap,
        subscription: payload.subscription,
      },
    };
  }),

  on(IAP_ACTIONS.pluginLoadSuccess, (state): AppState => {
    return { ...state, iap: { ...state.iap, storeReady: true } };
  }),
  on(IAP_ACTIONS.pluginLoadFailure, (state): AppState => {
    return { ...state, iap: { ...state.iap, storeReady: false } };
  }),

  on(IAP_ACTIONS.subscriptionPurchaseStart, (state): AppState => {
    return { ...state, iap: { ...state.iap, subscriptionPurchaseStatus: 'Ordering' } };
  }),
  on(IAP_ACTIONS.subscriptionPurchaseSuccess, (state): AppState => {
    return { ...state, iap: { ...state.iap, subscriptionPurchaseStatus: 'Ordered' } };
  }),
  on(IAP_ACTIONS.subscriptionPurchaseFailure, (state): AppState => {
    return { ...state, iap: { ...state.iap, subscriptionPurchaseStatus: 'Failed' } };
  }),

  on(IAP_ACTIONS.receiptValidationStart, (state): AppState => {
    return { ...state, iap: { ...state.iap, verifyReceiptStatus: 'Verifying' } };
  }),

  on(IAP_ACTIONS.receiptValidationSuccess, (state): AppState => {
    return { ...state, iap: { ...state.iap, verifyReceiptStatus: 'Verified' } };
  }),

  on(IAP_ACTIONS.receiptValidationFailure, (state): AppState => {
    return { ...state, iap: { ...state.iap, verifyReceiptStatus: 'Failed' } };
  }),

  on(IAP_ACTIONS.restorePurchasesStart, (state): AppState => {
    return { ...state, iap: { ...state.iap, restoringPurchases: true } };
  }),
  on(IAP_ACTIONS.restorePurchasesSuccess, (state): AppState => {
    return { ...state, iap: { ...state.iap, restoringPurchases: false } };
  }),
  on(IAP_ACTIONS.restorePurchasesFailure, (state): AppState => {
    return { ...state, iap: { ...state.iap, restoringPurchases: false } };
  }),
);
