import { HttpErrorResponse } from '@angular/common/http';
import { Params } from '@angular/router';
import {
  AccountsResponse,
  AuthorizationDetails,
  CAFileListResponse,
  MeasurementType,
  PortabilityReport,
  ReadAccountsResponse,
  ReauthorizationDetails,
  SourceType,
  UserAD,
  UserResponse,
} from '@digi.me/models';
import { OrganizationType, ShareFile, StorageResource } from '@globals';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { NotificationReceipt } from 'src/app/widgets/digi.me/models/notification-receipt.model';
import { Notification } from 'src/app/widgets/digi.me/models/notification.model';

export type Trigger = 'all' | 'loading' | string | undefined;

export const CLAIM_API_ACTIONS = createActionGroup({
  source: 'Digi.me Claim Api',
  events: {
    'Claim Requested': props<{ library: string }>(),
    'Claim Load Succeeded': emptyProps(),
    'Claim Load Failed': props<{ error: HttpErrorResponse }>(),
  },
});

export interface AuthorizeUrlRequest {
  createNewUser: boolean;
  sourceFetch: boolean;
  sourceType: SourceType;
  serviceId?: string;
  organizationType?: OrganizationType;
}

export const AUTHORIZE_URL_API_ACTIONS = createActionGroup({
  source: 'Digi.me AuthorizeUrl Api',
  events: {
    'Authorize Url Requested': props<AuthorizeUrlRequest>(),
    'Authorize Url Load Succeeded': props<{ url: AuthorizationDetails }>(),
    'Authorize Url Load Failed': props<{ error: HttpErrorResponse }>(),
  },
});

export const REVOKE_URL_API_ACTIONS = createActionGroup({
  source: 'Digi.me Revoke Api',
  events: {
    'Revoke Requested': props<{ accountId: string }>(),
    'Revoke Load Succeeded': props<{ url: string }>(),
    'Revoke Load Failed': props<{ error: HttpErrorResponse }>(),
  },
});

export const REAUTHORIZE_URL_API_ACTIONS = createActionGroup({
  source: 'Digi.me Reauthorize Api',
  events: {
    'Reauthorize Requested': props<{ accountId: string }>(),
    'Reauthorize Load Succeeded': props<{ details: ReauthorizationDetails }>(),
    'Reauthorize Load Failed': props<{ error: HttpErrorResponse }>(),
  },
});

export const PORTABILITY_REPORT_API_ACTIONS = createActionGroup({
  source: 'Digi.me Portability Report Api',
  events: {
    'Portability Report Requested': props<{ from?: number; to?: number }>(),
    'Portability Report Load Succeeded': props<{ report: PortabilityReport }>(),
    'Portability Report Load Failed': props<{ error: HttpErrorResponse }>(),
    'Portability Report Show Prompt Modal': emptyProps(),
    'Portability Report Reset Prompt Modal': emptyProps(),
    'Portability Report Prompt Modal Requested': emptyProps(),
  },
});

export const READ_ACCOUNTS_API_ACTIONS = createActionGroup({
  source: 'Digi.me Accounts Api',
  events: {
    'Accounts Requested': emptyProps(),
    'Accounts Load Succeeded': props<{ accounts: ReadAccountsResponse }>(),
    'Accounts Load Failed': props<{ error: HttpErrorResponse }>(),
  },
});

export const FILES_API_ACTIONS = createActionGroup({
  source: 'Digi.me Files Api',
  events: {
    'Files Requested': props<{ sourceFetch: boolean; trigger: Trigger; accountId?: string }>(),
    'Files Load Succeeded': props<{ sourceFetch: boolean; trigger: Trigger }>(),
    'Files Load Failed': props<{ error: HttpErrorResponse }>(),
    'Files Load Timeout': props<{ sourceFetch: boolean; trigger: Trigger }>(),
    'Files Requested Initiated': props<{ sourceFetch: boolean; trigger: Trigger; accountId?: string }>(),
  },
});

export const STORAGE_FILE_API_ACTIONS = createActionGroup({
  source: 'Digi.me Storage File Api',
  events: {
    'Storage Files Requested': emptyProps(),
    'Storage Files Load Succeeded': props<{ files: StorageResource[] }>(),
    'Storage Files Load Failed': props<{ error: HttpErrorResponse }>(),
    'Reset Storage State': emptyProps(),
    'Add Storage File': props<{
      file: StorageResource;
      fileName: string;
      filePath?: string;
      measurementType: MeasurementType;
    }>(),
    'Add Apple Health Storage File': props<{
      file: StorageResource;
      fileName: string;
      filePath?: string;
      measurementType: MeasurementType;
    }>(),
    'Apple Health Storage Files Succeeded': emptyProps(),
    'Apple Health Storage Files Failed': emptyProps(),
    'Reset Apple Health Storage Files': emptyProps(),
    'Add Apple Health Storage File Succeeded': props<{
      file: StorageResource;
      fileName: string;
      measurementType: MeasurementType;
    }>(),
    'Add Apple Health Storage File Failed': props<{
      error: HttpErrorResponse;
      file: StorageResource;
      fileName: string;
      measurementType: MeasurementType;
    }>(),
    'Add Storage File Succeeded': props<{
      file: StorageResource;
      fileName: string;
      measurementType: MeasurementType;
    }>(),
    'Add Storage File Failed': props<{
      error: HttpErrorResponse;
      file: StorageResource;
      fileName: string;
      measurementType: MeasurementType;
    }>(),
    'Delete Storage File Failed': props<{ error: HttpErrorResponse }>(),
    'Update Storage File': props<{ file: StorageResource; filePath: string }>(),
    'Add Storage Share File': props<{
      file: ShareFile;
      fileName: string;
      filePath?: string;
    }>(),
    'Add Storage Share File Succeeded': props<{ file: ShareFile; fileName: string }>(),
    'Add Storage Share File Failed': props<{ error: HttpErrorResponse; file: ShareFile; fileName: string }>(),
    'Add Storage Notification Receipt': props<{
      file: NotificationReceipt;
      fileName: string;
      filePath?: string;
    }>(),
    'Add Storage Notification Receipt Succeeded': props<{ file: NotificationReceipt; fileName: string }>(),
    'Add Storage Notification Receipt Failed': props<{
      error: HttpErrorResponse;
      file: NotificationReceipt;
      fileName: string;
    }>(),
  },
});

export const FILE_LIST_API_ACTIONS = createActionGroup({
  source: 'Digi.me File List Api',
  events: {
    'File List Requested': emptyProps(),
    'File List Load Succeeded': props<{ fileList: CAFileListResponse }>(),
    'File List Load Failed': props<{ error: HttpErrorResponse }>(),
  },
});

export const NOTIFICATIONS_API_ACTIONS = createActionGroup({
  source: 'Digi.me Force Update Api',
  events: {
    'Notification Requested': emptyProps(),
    'Notification Succeeded': props<{ notification: Notification }>(),
    'Notification Failed': props<{ error: HttpErrorResponse }>(),
    'Notification Dismissed': props<{ notificationReceipt: NotificationReceipt }>(),
  },
});

export const STORAGE_FILE_LIST_API_ACTIONS = createActionGroup({
  source: 'Digi.me Storage File List Api',
  events: {
    'Storage File List Requested': emptyProps(),
    'Storage File List Load Succeeded': props<{ fileList: CAFileListResponse }>(),
    'Storage File List Load Failed': props<{ error: any }>(),
  },
});

export const RESET_API_ACTIONS = createActionGroup({
  source: 'Digi.me Reset Api',
  events: {
    'Reset Requested': emptyProps(),
    'Reset Succeeded': emptyProps(),
    'Reset Failed': props<{ error: HttpErrorResponse }>(),
  },
});

export const USER_API_ACTIONS = createActionGroup({
  source: 'Digi.me User Api',
  events: {
    'User Data Requested': props<{ sourceFetch: boolean; trigger: Trigger }>(),
    'User Data Load Succeeded': props<{ user: UserResponse; trigger: Trigger }>(),
    'User Data Load Failed': props<{ error: HttpErrorResponse }>(),
    'User Data Save User': props<{ user: UserResponse }>(),
    'User Data Security Prompt': props<{ user: UserResponse }>(),
  },
});

export const SHARE_PROVIDERS_ACTION = createActionGroup({
  source: 'Show Share Providers',
  events: {
    'Show Previously Used Share Providers': emptyProps(),
  },
});

export const SIGN_UP_API_ACTIONS = createActionGroup({
  source: 'Azure B2C Sign Up',
  events: {
    'Sign Up Started': props<{ library: string }>(),
    'Sign Up Asked': props<{ library: string }>(),
  },
});

export const CANCEL_SIGN_UP_API_ACTIONS = createActionGroup({
  source: 'Cancel Azure B2C Sign Up',
  events: {
    'Cancel Sign Up Started': emptyProps(),
    'Cancel Sign Up Succeeded': emptyProps(),
  },
});

/**
 * The set of action that can be dispatch after a return from the Digi.me Saas platform.
 *
 * Authorize: https://digime.github.io/digime-sdk-nodejs/pages/fundamentals/authorize.html
 * Onboard: https://digime.github.io/digime-sdk-nodejs/pages/fundamentals/onboard.html
 * Reauthorize: https://digime.github.io/digime-sdk-nodejs/pages/fundamentals/reauthorize.html
 */
export const DIGI_ME_SAAS_RETURN_ACTIONS = createActionGroup({
  source: 'Digi.me Saas Return',
  events: {
    'Authorize Succeeded': props<{ params: Params }>(),
    'Authorize Failed': props<{ params: Params }>(),
    'Onboard Succeeded': props<{ params: Params }>(),
    'Reauthorize Completed': props<{ params: Params }>(),
    'Revoke Completed': props<{ params: Params }>(), // TODO: Use revoke when we switch to different return components,
    'Manage Permissions Cancelled': emptyProps(),
  },
});

export const EXCHANGE_CODE_FOR_TOKEN_API_ACTIONS = createActionGroup({
  source: 'Digi.me Exchange Code For Token',
  events: {
    'Exchange Code For Token Failed': props<{ error: HttpErrorResponse }>(),
  },
});

export const RESET_UI_ACTIONS = createActionGroup({
  source: 'Digi.me Reset UI',
  events: {
    'Reset Warning Requested': emptyProps(),
    'Reset Warning Dismissed': emptyProps(),
  },
});

export const CLEAR_UI_DATA_ACTIONS = createActionGroup({
  source: 'Digi.me Clear UI Data',
  events: {
    'Clear Data Requested': emptyProps(),
  },
});

export const CHANGE_PASSWORD_API_ACTIONS = createActionGroup({
  source: 'Azure B2C Reset Password',
  events: {
    'Change Password Started': emptyProps(),
  },
});

export const DELETE_API_ACTIONS = createActionGroup({
  source: 'Digi.me Delete Api',
  events: {
    'Delete Requested': emptyProps(),
    'Delete Succeeded': emptyProps(),
    'Delete Failed': props<{ error: HttpErrorResponse }>(),
  },
});

export const DELETE_ACCOUNT_API_ACTIONS = createActionGroup({
  source: 'Digi.me Delete Account Api',
  events: {
    'Delete Account Requested': props<{ accountId: string }>(),
    'Delete Account Succeeded': emptyProps(),
    'Delete Account Failed': props<{ error: HttpErrorResponse }>(),
  },
});

export const UPDATE_AD_USER_API_ACTIONS = createActionGroup({
  source: 'Digi.me Update AD User Api',
  events: {
    'Update AD User Requested': props<{ user: UserAD }>(),
    'Update AD User Succeeded': props<{ user: UserAD }>(),
    'Update AD User Failed': props<{ error: HttpErrorResponse }>(),
  },
});

export const USER_AD_API_ACTIONS = createActionGroup({
  source: 'Digi.me User AD Api',
  events: {
    'User AD Requested': emptyProps(),
    'User AD Succeeded': props<UserAD>(),
    'User AD Failed': props<{ error: HttpErrorResponse }>(),
  },
});

export const DELETE_UI_ACTIONS = createActionGroup({
  source: 'Digi.me Delete UI',
  events: {
    'Delete Warning Requested': emptyProps(),
    'Delete Warning Dismissed': props<{ cancel: boolean }>(),
    'Delete Warning From Security Prompt': emptyProps(),
    'Delete Warning Initiate': emptyProps(),
  },
});

export const SHARE_MEASUREMENTS = createActionGroup({
  source: 'Share MEASUREMENTS UI',
  events: {
    'Find Account Succeeded': props<{ account: AccountsResponse }>(),
    Reset: emptyProps(),
  },
});

export const BINARY_ACTIONS = createActionGroup({
  source: 'Binary Download',
  events: {
    Requested: props<{ id: string }>(),
  },
});

export const REVIEW_API_ACTIONS = createActionGroup({
  source: 'Digi.me Patient Federation Review Api',
  events: {
    'Url Requested': emptyProps(),
    'Url Succeeded': props<{ url: string }>(),
    'Url Failed': props<{ error: HttpErrorResponse }>(),
    'Review Submitted': emptyProps(),
  },
});

// TODO: Move these
export const SUMMARY_EXPORT_ACTIONS = createActionGroup({
  source: 'Patient summary pdf export',
  events: {
    'Pdf Export Requested': emptyProps(),
    'Pdf Export Succeeded': emptyProps(),
    'Pdf Export Failed': props<{ error: Error }>(),
  },
});

// TODO: Move these
export const SUMMARY_VIEW_ACTIONS = createActionGroup({
  source: 'Patient summary view',
  events: {
    'Summary View Requested': emptyProps(),
    'Summary View Succeeded': emptyProps(),
    'Summary View Failed': props<{ error: Error }>(),
  },
});

/**
 * Grouped set of actions that are errors returned from the API.
 *
 * Add any new ones in here for logging and showing error modals.
 */
export const API_ERROR_ACTIONS = [
  READ_ACCOUNTS_API_ACTIONS.accountsLoadFailed,
  FILES_API_ACTIONS.filesLoadFailed,
  AUTHORIZE_URL_API_ACTIONS.authorizeUrlLoadFailed,
  FILE_LIST_API_ACTIONS.fileListLoadFailed,
  REAUTHORIZE_URL_API_ACTIONS.reauthorizeLoadFailed,
  USER_API_ACTIONS.userDataLoadFailed,
  DELETE_ACCOUNT_API_ACTIONS.deleteAccountFailed,
  REVOKE_URL_API_ACTIONS.revokeLoadFailed,
  NOTIFICATIONS_API_ACTIONS.notificationFailed,
  UPDATE_AD_USER_API_ACTIONS.updateADUserFailed,
  USER_AD_API_ACTIONS.userADFailed,
  RESET_API_ACTIONS.resetFailed,
  CLAIM_API_ACTIONS.claimLoadFailed,
  DELETE_API_ACTIONS.deleteFailed,
  REVIEW_API_ACTIONS.urlFailed,
  PORTABILITY_REPORT_API_ACTIONS.portabilityReportLoadFailed,
];
