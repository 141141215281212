import { AccountStatus, getAccountsWithWarningsByObjectTypeError } from '@accounts';
import { AccountsResponse, FitnessApps, Status } from '@digi.me/models';
import { createSelector } from '@ngrx/store';
import { LoadingState } from '@store';
import { DigiMeState } from '../digi-me.reducer';
import { selectFileListStatus } from './digi-me-file-list.selectors';
import { selectUserFitnessIds } from './digi-me-user.selectors';
import { selectDigiMeState } from './digi-me.selectors';

export const selectAccountsResponse = createSelector(selectDigiMeState, (state: DigiMeState) => {
  return state?.accounts.data;
});

export const selectAccountsIsInitializingOrLoading = createSelector(
  selectDigiMeState,
  (state: DigiMeState) =>
    state.accounts.callState === LoadingState.init || state.accounts.callState === LoadingState.loading,
);

export const selectAccounts = createSelector(selectAccountsResponse, (accountsResponse) => {
  const accounts: AccountsResponse[] = [];

  accountsResponse?.accounts.map((account) => {
    accounts.push(account);
  });

  return accounts;
});

export const selectAccountsCount = createSelector(
  selectAccountsResponse,
  (accountsResponse) => accountsResponse?.accounts?.length ?? null,
);

export const selectAccountById = (accountId: string) =>
  createSelector(selectAccounts, (accounts) => accounts.find((account) => account.id === accountId));

export const selectAccountByReference = (reference: string) =>
  createSelector(selectAccounts, (accounts) => accounts.find((account) => account.reference === reference));

/**
 * Selects and returns the pull accounts.
 *
 * @param state - The current state of the accounts.
 * @returns An array of accounts with a type of 'USER'.
 */
export const selectPullAccounts = createSelector(selectAccounts, (accounts: AccountsResponse[]) =>
  accounts?.filter((account: AccountsResponse) => account.type === 'USER'),
);

/**
 * Selects the accounts with their corresponding statuses.
 *
 * @param selectAccounts - The selector function to retrieve the accounts.
 * @param selectFileListStatus - The selector function to retrieve the file list status.
 * @returns An array of objects containing the account and its status.
 */
export const selectAccountsWithStatuses = createSelector(
  selectAccounts,
  selectFileListStatus,
  (accounts: AccountsResponse[], fileListState: Status | undefined) => {
    return accounts?.map((account) => {
      const accountId = account.id;
      const status = fileListState?.details?.[accountId];
      return { account, status };
    });
  },
);

/**
 * Selects the accounts with their corresponding statuses. Filter out medical or fitness and wellbeing accounts.
 *
 * @param selectAccounts - The selector function to retrieve the accounts.
 * @param selectFileListStatus - The selector function to retrieve the file list status.
 * @returns An array of objects containing the account and its status.
 */
export const selectMedicalOrFitnessWellbeingAccounts = (type: 'medical' | 'fitness') =>
  createSelector(
    selectAccounts,
    selectFileListStatus,
    selectUserFitnessIds,
    (accounts: AccountsResponse[], fileListState: Status | undefined, fitnessIdsSelector: FitnessApps | undefined) => {
      const fitnessIds = fitnessIdsSelector ? Object.values(fitnessIdsSelector) : [];
      return accounts
        ?.map((account) => {
          const accountId = account.id;
          const status = fileListState?.details?.[accountId];
          return { account, status };
        })
        .filter(({ account }) => account.type !== 'PUSH')
        .filter(({ account }) =>
          type === 'medical' ? !fitnessIds.includes(`${account.sourceId}`) : fitnessIds.includes(`${account.sourceId}`),
        );
    },
  );

/**
 * Selects the accounts that we have shared data with already.
 *
 * @param selectAccounts - The selector function to retrieve the accounts.
 * @returns An array of objects containing the account that we have pushed to data already.
 */
export const selectPushProviders = createSelector(selectAccounts, (accounts: AccountsResponse[]) => {
  return accounts
    ?.map((account) => {
      return account;
    })
    .filter((account) => account.type === 'PUSH');
});

/**
 * Selects accounts with warnings based on the specified object types.
 *
 * @param objectTypes - An array of object types.
 * @returns An array of accounts with warnings, or null if no accounts have warnings.
 */
export const selectAccountsWarningsByObjectTypes = (objectTypes: number[]) =>
  createSelector(selectAccountsWithStatuses, (aws: AccountStatus[]) => {
    const accountsWithWarnings = getAccountsWithWarningsByObjectTypeError(aws, objectTypes);
    return accountsWithWarnings.length > 0 ? accountsWithWarnings : null;
  });

/**
 * Selects the ids of the onboarded fitness apps.
 */
export const selectOnboardedFitnessAppIds = createSelector(
  selectMedicalOrFitnessWellbeingAccounts('fitness'),
  (accounts) => accounts?.map((account) => account.account?.sourceId?.toString()) ?? [],
);
