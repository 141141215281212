export interface MenuItem {
  id?: string;
  header?: string;
  headerTopMenu?: string;
  path?: string;
  /**
   * The name of the svg to display on the menu.
   */
  icon?: string;
  subMenu?: MenuItem[];
  expand?: boolean;
  parent?: MenuItem | null;
  placeholder?: boolean;
  selected?: boolean;
  /**
   * CSS Style class to display a custom icon
   */
  customIconClass?: string;
  disabled?: boolean;
  working?: boolean;
}

export function sortMenu(a: MenuItem, b: MenuItem): number {
  return (a.header ?? a.id ?? '') > (b.header ?? b.id ?? '') ? 1 : -1;
}

// Helper type to enforce uniqueness
export type UniqueMenuItems<T extends MenuItem[]> = {
  [K in keyof T]: K extends keyof any[] ? T[K] : T[K]['id'] extends T[number]['id'] & {} ? T[K] : never;
} extends infer R
  ? R extends T
    ? T
    : never
  : never;
