import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ApiService, SubscriptionService } from '@core/services';
import { environment } from '@environments/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, from, map, of, tap } from 'rxjs';
import { APP_ACTIONS, SUBSCRIPTION_ACTIONS } from '../actions';

@Injectable()
export class IapEffects {
  checkProductStatus$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(APP_ACTIONS.authenticated),
        filter(() => environment.features.subscriptions),
        exhaustMap(() =>
          from(this.apiService.checkSubscriptionStatus()).pipe(
            map((response) => SUBSCRIPTION_ACTIONS.subscriptionLoadFinished({ subscription: response.body })),
            catchError((error) => of(SUBSCRIPTION_ACTIONS.subscriptionLoadFailed({ error }))),
          ),
        ),
      );
    },
    { dispatch: true },
  );

  loadIAPStore$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SUBSCRIPTION_ACTIONS.subscriptionLoadFinished),
        filter(({ subscription: product }) => Capacitor.isNativePlatform() && !product?.active),

        tap(() => {
          this.subscriptionService.init();
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly subscriptionService: SubscriptionService,
    private readonly apiService: ApiService,
  ) {}
}
