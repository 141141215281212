import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { DeviceService } from '@core/services';
import { getSourceType } from '@globals';
import { Logger } from '@logging';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SHARE_OBSERVATION_ACTIONS } from '@store/hl7fhir';
import { exhaustMap, filter, mergeMap, tap } from 'rxjs/operators';
import {
  API_ERROR_ACTIONS,
  AUTHORIZE_URL_API_ACTIONS,
  CANCEL_SIGN_UP_API_ACTIONS,
  DELETE_API_ACTIONS,
  DIGI_ME_SAAS_RETURN_ACTIONS,
  FILES_API_ACTIONS,
  NOTIFICATIONS_API_ACTIONS,
  READ_ACCOUNTS_API_ACTIONS,
  RESET_API_ACTIONS,
  SIGN_UP_API_ACTIONS,
  SUMMARY_EXPORT_ACTIONS,
  SUMMARY_VIEW_ACTIONS,
  USER_API_ACTIONS,
} from '../../../digi.me/digi-me.actions';
import { USER_ACTIONS } from '../../../user/user.actions';
import { APP_ACTIONS, DOCUMENT_DOWNLOAD_ACTIONS } from '../../actions/app.actions';

@Injectable()
export class EventLogEffects {
  logUserVisit$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(APP_ACTIONS.userVisit),
        tap(() => {
          this.deviceService.removeFromLocalStorage();
        }),
        exhaustMap(() => {
          // The router store isn't initialized yet when this event is fired
          // so we parse the query params ourselves.
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);

          const utm_source = urlParams.get('utm_source');

          const utm_medium = urlParams.get('utm_medium');

          const utm_campaign = urlParams.get('utm_campaign');

          let properties = {};
          if (utm_source || utm_medium || utm_campaign) {
            properties = { utm_campaign, utm_medium, utm_source };

            // Remove the campaign tracking from the URL
            const removeParams = {
              utm_campaign: null,
              utm_medium: null,
              utm_source: null,
            };
            this.router.navigate([], { queryParams: removeParams, queryParamsHandling: 'merge' });
          }
          return this.logger.logEvent('user_visit', properties);
        }),
      );
    },
    { dispatch: false },
  );

  logDeleteData$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RESET_API_ACTIONS.resetRequested),
        exhaustMap(() => this.logger.logEvent('data_delete')),
      );
    },
    { dispatch: false },
  );

  logDeleteAccount$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DELETE_API_ACTIONS.deleteRequested),
        exhaustMap(() => this.logger.logEvent('user_delete')),
      );
    },
    { dispatch: false },
  );

  logSourceAdd$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AUTHORIZE_URL_API_ACTIONS.authorizeUrlRequested),
        exhaustMap(({ sourceType }) => this.logger.logEvent('source_add', { source_interaction_type: sourceType })),
      );
    },
    { dispatch: false },
  );

  logAuthenticationStarted$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(APP_ACTIONS.authenticationStarted),
        filter((action) => action.skipLog !== true),
        tap(() => {
          this.deviceService.removeFromLocalStorage();
        }),
        exhaustMap(() => this.logger.logEvent('sign_in_start')),
      );
    },
    { dispatch: false },
  );

  logAuthenticated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(APP_ACTIONS.authenticated),
        filter((action) => action.isNewUser !== true),
        tap(() => {
          this.deviceService.removeFromLocalStorage();
        }),
        exhaustMap(() => this.logger.logEvent('sign_in_success')),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect that logs a "signup success" event when a user is authenticated as a new user using Mixpanel.
   **/
  logSignupSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(APP_ACTIONS.authenticated),
        filter((action) => action.isNewUser === true),
        exhaustMap(() => this.logger.logEvent('sign_up_success')),
      );
    },
    { dispatch: false },
  );

  logUserLoadedSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(USER_API_ACTIONS.userDataLoadSucceeded),
        exhaustMap(() => this.logger.logEvent('user_success')),
      );
    },
    { dispatch: false },
  );

  logUserMustOnboard$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(USER_API_ACTIONS.userDataLoadFailed),
        filter((action) => action.error.status === 403),
        exhaustMap(() => this.logger.logEvent('source_account_not_loaded')),
      );
    },
    { dispatch: false },
  );

  cancelSignUp$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CANCEL_SIGN_UP_API_ACTIONS.cancelSignUpSucceeded),
        exhaustMap(() => this.logger.logEvent('sign_up_abandoned')),
      );
    },
    { dispatch: false },
  );

  logDocumentOpenRequest$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DOCUMENT_DOWNLOAD_ACTIONS.downloadStarted),
        exhaustMap(({ documentType, startedFrom }) =>
          this.logger.logEvent('document_open_started', {
            document_type: documentType,
            started_from: startedFrom,
          }),
        ),
      );
    },
    { dispatch: false },
  );

  logDocumentOpen$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DOCUMENT_DOWNLOAD_ACTIONS.downloadSuccess),
        exhaustMap(({ documentType }) =>
          this.logger.logEvent('document_open_success', { document_type: documentType }),
        ),
      );
    },
    { dispatch: false },
  );

  logDocumentOpenFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DOCUMENT_DOWNLOAD_ACTIONS.downloadFailure),
        exhaustMap(({ documentType, error }) =>
          this.logger.logEvent('document_open_failure', { document_type: documentType, error }),
        ),
      );
    },
    { dispatch: false },
  );

  logAuthenticationFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(APP_ACTIONS.authenticationError),
        exhaustMap((action) => this.logger.logEvent('sign_in_fail', action.error)),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect that logs the failure of authorizing the first onboarded service a service.
   */
  logOnboardServiceFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DIGI_ME_SAAS_RETURN_ACTIONS.authorizeFailed),
        exhaustMap(({ params }) => {
          const additionalProperties: Record<string, any> = {
            error_name: params['errorCode'],
            error_origin: 'source',
          };

          return this.logger.logEvent('source_auth_fail', additionalProperties);
        }),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect that logs the success of authorizing the first onboarded service.
   */
  logOnboardServiceSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DIGI_ME_SAAS_RETURN_ACTIONS.authorizeSucceeded),
        exhaustMap(() => this.logger.logEvent('source_auth_success')),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect that logs the start of the sign-up process.
   * It listens for the `signUpStarted` and `signUpWithoutLibClaim` actions and tracks the event using Mixpanel.
   */
  logSignupStart$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SIGN_UP_API_ACTIONS.signUpStarted, USER_ACTIONS.signUpWithoutLibClaim),
        exhaustMap(() => this.logger.logEvent('sign_up_start')),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect that logs the request to retrieve files with the option to refresh data on the server.
   * If sourceFetch is true, it tracks the event 'data_refresh_started',
   * otherwise it tracks the event 'data_read_started'.
   * It listens for the `filesRequested` action and tracks the event using Mixpanel.
   */
  logFilesRequested$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FILES_API_ACTIONS.filesRequested),
        exhaustMap(({ sourceFetch }) =>
          this.logger.logEvent(sourceFetch ? 'data_refresh_started' : 'data_read_started'),
        ),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect that tracks the event when files are loaded.
   * It listens for the `filesLoadSucceeded` action and tracks the event using Mixpanel.
   */
  logFilesLoaded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FILES_API_ACTIONS.filesLoadSucceeded),
        exhaustMap(() => this.logger.logEvent('data_read_success')),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect that tracks the event when files loading has timed out.
   * It listens for the `filesLoadTimeout` action and tracks the event using Mixpanel.
   */
  logFilesTimeout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FILES_API_ACTIONS.filesLoadTimeout),
        exhaustMap(() => this.logger.logEvent('data_read_timeout')),
      );
    },
    { dispatch: false },
  );

  /* eslint-disable @ngrx/no-multiple-actions-in-effects */
  /**
   * Effect that logs the loaded accounts.
   * Retrieves the necessary information from the accounts and tracks the event using Mixpanel.
   */
  logAccountsLoaded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(READ_ACCOUNTS_API_ACTIONS.accountsLoadSucceeded),
        mergeMap(({ accounts }) => accounts.accounts),
        mergeMap((account) => {
          const additionalProperties = {
            source_type_name: account.serviceTypeName,
            source_type_id: account.serviceTypeId,
            source_group_id: account.serviceGroupId,
            source_group_name: account.serviceGroupName,
            source_provider_name: account.serviceProviderName,
            source_provider_reference: account.serviceProviderReference,
            source_is_sample: account.sample ?? false,
            source_interaction_type: account.type === 'USER' ? 'pull' : 'push',
          };

          return this.logger.logEvent('source_account_loaded', additionalProperties);
        }),
      );
    },
    { dispatch: false },
  );
  /* eslint-enable @ngrx/no-multiple-actions-in-effects */

  /**
   * Effect that logs the user sign out event.
   * It tracks the 'sign_out' event using the Mixpanel service.
   */
  logUserSignOut$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(USER_ACTIONS.signOut),
        exhaustMap(() => this.logger.logEvent('sign_out')),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect that logs the uses' force logout due to inactivity.
   * It tracks the 'session_expired' event using the Mixpanel service.
   */
  sessionTimeout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(APP_ACTIONS.timeoutLogout),
        exhaustMap(() => this.logger.logEvent('session_expired')),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect that logs the users summary export when it is started.
   * It tracks the 'patient_summary_export_started' event using the Mixpanel service.
   */
  summaryExportStarted$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SUMMARY_EXPORT_ACTIONS.pdfExportRequested),
        exhaustMap(() => this.logger.logEvent('patient_summary_export_started')),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect that logs the users summary export if it succeeded.
   * It tracks the 'patient_summary_export_success' event using the Mixpanel service.
   */
  summaryExportSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SUMMARY_EXPORT_ACTIONS.pdfExportSucceeded),
        exhaustMap(() => this.logger.logEvent('patient_summary_export_success')),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect that logs the users summary export if it fails.
   * It tracks the 'patient_summary_export_failure' event using the Mixpanel service.
   */
  summaryExportFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SUMMARY_EXPORT_ACTIONS.pdfExportFailed),
        exhaustMap((error) => this.logger.logEvent('patient_summary_export_failure', {}, error.error)),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect that logs when a user selects to view their patient summary in the app.
   * It tracks the 'patient_summary_view_started' event using the Mixpanel service.
   */
  summaryViewRequested$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SUMMARY_VIEW_ACTIONS.summaryViewRequested),
        exhaustMap(() => this.logger.logEvent('patient_summary_view_started')),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect that logs when patient summary is created successfully and shown in the app.
   * It tracks the 'patient_summary_view_success' event using the Mixpanel service.
   */
  summaryViewSucceeded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SUMMARY_VIEW_ACTIONS.summaryViewSucceeded),
        exhaustMap(() => this.logger.logEvent('patient_summary_view_success')),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect that logs when patient summary fails to generate and get shown in the app.
   * It tracks the 'patient_summary_view_failure' event using the Mixpanel service.
   */
  summaryViewFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SUMMARY_VIEW_ACTIONS.summaryViewFailed),
        exhaustMap((error) => this.logger.logEvent('patient_summary_view_failure', {}, error.error)),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect that logs the share to provider success/failure.
   * Retrieves the necessary information from the share and tracks the event using Mixpanel.
   */
  logShareSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SHARE_OBSERVATION_ACTIONS.shareToProvider),
        exhaustMap(({ observation, success }) => {
          const properties = {
            type: observation.resourceType,
            source: getSourceType(observation),
          };

          return this.logger.logEvent(success ? 'record_share_success' : 'record_share_failure', properties);
        }),
      );
    },
    { dispatch: false },
  );

  /**
   * Effect that logs when user dismisses a notification
   * It tracks the 'notification_receipt' event using the Mixpanel service.
   */
  notificationReceipt$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NOTIFICATIONS_API_ACTIONS.notificationDismissed),
        exhaustMap((body) => {
          const receipt = body.notificationReceipt;
          const properties = {
            name: receipt.name,
            description: receipt.description,
            id: receipt.id,
            timestamp: receipt.timestamp,
          };

          return this.logger.logEvent('notification_receipt', properties);
        }),
      );
    },
    { dispatch: false },
  );

  // * EXCEPTION HANDLING
  sendException$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...API_ERROR_ACTIONS),
        exhaustMap((error) => this.logger.logApiException(error.error)),
      );
    },
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly logger: Logger,
    private readonly router: Router,
    private readonly deviceService: DeviceService,
  ) {}

  /**
   * Retrieves the library value from the given params object.
   * @param params - The params object containing the state parameter.
   * @returns The library value if found, otherwise undefined.
   */
  getLibraryFrom(params: Params): string | undefined {
    const state = decodeURIComponent(params['state']);
    const stateParams = new URLSearchParams(state);
    return stateParams.get('library') ?? undefined;
  }
}
