import { createSelector } from '@ngrx/store';
import { LoadingState } from '@store';
import { AuthorizeUrlRequest } from '../digi-me.actions';
import { DigiMeState } from '../digi-me.reducer';
import { selectDigiMeState } from './digi-me.selectors';

export interface IsLoadingAuthorizeUrl { isLoading: boolean; trigger: AuthorizeUrlRequest | null }
export const selectIsAuthorizeUrlLoading = createSelector(
  selectDigiMeState,
  (state: DigiMeState): IsLoadingAuthorizeUrl => {
    return {
      isLoading: state.onboarding.callState === LoadingState.loading,
      trigger: state.onboarding.trigger,
    };
  },
);
