import { Injectable } from '@angular/core';
import { Logger } from '@logging';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IAP_ACTIONS, PAYWALL_VIEW_ACTIONS, SUBSCRIPTION_ACTIONS } from '@store/app/actions';
import { exhaustMap } from 'rxjs';

@Injectable()
export class IapEventLogEffects {
  iapPluginLoadStart$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IAP_ACTIONS.pluginLoadStart),
        exhaustMap(() => this.logger.logEvent('iap_plugin_load_start')),
      );
    },
    { dispatch: false },
  );

  iapPluginLoadSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IAP_ACTIONS.pluginLoadSuccess),
        exhaustMap(() => this.logger.logEvent('iap_plugin_load_success')),
      );
    },
    { dispatch: false },
  );

  iapPluginLoadFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IAP_ACTIONS.pluginLoadFailure),
        exhaustMap(({ errors }) => {
          const filteredErrors = errors?.map((error) => {
            return {
              error_code: error.code,
              error_message: error.message,
              product_id: error.productId,
            };
          });

          return this.logger.logEvent('iap_plugin_load_failure', {
            errors: filteredErrors,
          });
        }),
      );
    },
    { dispatch: false },
  );

  paywallViewOpened$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PAYWALL_VIEW_ACTIONS.opened),
        exhaustMap(() => {
          return this.logger.logEvent('iap_paywall_view_opened');
        }),
      );
    },
    { dispatch: false },
  );

  paywallViewClosed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PAYWALL_VIEW_ACTIONS.closed),
        exhaustMap(() => {
          return this.logger.logEvent('iap_paywall_view_closed');
        }),
      );
    },
    { dispatch: false },
  );

  productPurchaseStart$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IAP_ACTIONS.subscriptionPurchaseStart),
        exhaustMap(({ subscription }) => {
          const { productId, offerId } = subscription;

          return this.logger.logEvent('iap_product_purchase_start', {
            product_id: productId,
            offer_id: offerId,
          });
        }),
      );
    },
    { dispatch: false },
  );

  productPurchaseSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IAP_ACTIONS.subscriptionPurchaseSuccess),
        exhaustMap(({ subscription }) => {
          const { productId, offerId } = subscription;

          return this.logger.logEvent('iap_product_purchase_success', {
            product_id: productId,
            offer_id: offerId,
          });
        }),
      );
    },
    { dispatch: false },
  );

  productPurchaseFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IAP_ACTIONS.subscriptionPurchaseFailure),
        exhaustMap(({ subscription }) => {
          const { error, productId, offerId } = subscription;

          let filteredError = {
            product_id: productId,
            offer_id: offerId,
            error_code: error?.code,
            error_message: error?.message,
          };

          return this.logger.logEvent('iap_product_purchase_failure', {
            ...filteredError,
          });
        }),
      );
    },
    { dispatch: false },
  );

  transactionStart$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IAP_ACTIONS.transactionStart),
        exhaustMap(({ transaction }) => {
          const { transactionState, productId, transactionId, purchaseDate } = transaction;

          return this.logger.logEvent('iap_transaction_start', {
            transaction_id: transactionId,
            transaction_state: transactionState,
            product_id: productId,
            product_purchase_date: purchaseDate,
          });
        }),
      );
    },
    { dispatch: false },
  );

  transactionApproveSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IAP_ACTIONS.transactionApproved),
        exhaustMap(({ transaction }) => {
          const { transactionState, productId, transactionId, purchaseDate } = transaction;

          return this.logger.logEvent('iap_transaction_approve_success', {
            transaction_id: transactionId,
            transaction_state: transactionState,
            product_id: productId,
            product_purchase_date: purchaseDate,
          });
        }),
      );
    },
    { dispatch: false },
  );

  transactionPending$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IAP_ACTIONS.transactionPending),
        exhaustMap(({ transaction }) => {
          const { transactionState, productId, transactionId, purchaseDate } = transaction;

          return this.logger.logEvent('iap_transaction_pending', {
            transaction_id: transactionId,
            transaction_state: transactionState,
            product_id: productId,
            purchase_date: purchaseDate,
          });
        }),
      );
    },
    { dispatch: false },
  );

  transactionFinished$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IAP_ACTIONS.transactionFinished),
        exhaustMap(({ transaction }) => {
          const { transactionState, productId, transactionId, purchaseDate } = transaction;

          return this.logger.logEvent('iap_transaction_finished', {
            transaction_id: transactionId,
            transaction_state: transactionState,
            product_id: productId,
            purchase_date: purchaseDate,
          });
        }),
      );
    },
    { dispatch: false },
  );

  receiptValidationStart$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IAP_ACTIONS.receiptValidationStart),
        exhaustMap(({ transaction }) => {
          return this.logger.logEvent('iap_receipt_validation_start', {
            transaction_id: transaction.transactionId,
            transaction_state: transaction.transactionState,
            product_id: transaction.productId,
          });
        }),
      );
    },
    { dispatch: false },
  );

  receiptValidationSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IAP_ACTIONS.receiptValidationSuccess),
        exhaustMap(({ receipt }) => {
          return this.logger.logEvent('iap_receipt_validation_success', {
            receipt_validation_date: receipt.validationDate,
            product_id: receipt.productId,
            transaction_id: receipt.transaction.transactionId,
            transaction_state: receipt.transaction.transactionState,
          });
        }),
      );
    },
    { dispatch: false },
  );

  receiptValidationFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IAP_ACTIONS.receiptValidationFailure),
        exhaustMap(({ receipt, error }) => {
          return this.logger.logEvent('iap_receipt_validation_failure', {
            transaction_id: receipt.transactionId,
            transaction_state: receipt.transactionState,
            product_id: receipt.productId,
            error_code: error.errorCode,
            error_message: error.errorMessage,
            error_status: error.errorStatus,
          });
        }),
      );
    },
    { dispatch: false },
  );

  restorePurchasesStart$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IAP_ACTIONS.restorePurchasesStart),
        exhaustMap(() => {
          return this.logger.logEvent('iap_restore_purchases_start');
        }),
      );
    },
    { dispatch: false },
  );

  restorePurchasesSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IAP_ACTIONS.restorePurchasesSuccess),
        exhaustMap(() => this.logger.logEvent('iap_restore_purchases_success')),
      );
    },
    { dispatch: false },
  );

  restorePurchasesFailure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(IAP_ACTIONS.restorePurchasesFailure),
        exhaustMap(({ error }) => {
          const { isError, platform, ...filteredError } = error;
          const properties = { ...filteredError };

          return this.logger.logEvent('iap_restore_purchases_failure', properties);
        }),
      );
    },
    { dispatch: false },
  );

  productStatusUpdated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SUBSCRIPTION_ACTIONS.updateFinished),
        exhaustMap(({ subscription }) => {
          const { purchaseDate, ...filteredSubscription } = subscription;

          return this.logger.logEvent('product_status_updated', {
            product_purchase_date: subscription.purchaseDate,
            ...filteredSubscription,
          });
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly logger: Logger,
  ) {}
}
