import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HeaderConfig, Svg } from '@globals';
import { SharedModule } from '@shared';

@Component({
  standalone: true,
  selector: 'app-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss'],
  imports: [SharedModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionHeaderComponent {
  @Input({ required: true }) headerConfig: HeaderConfig | undefined;

  get svgDefaultPath(): string {
    return Svg.defaultPath;
  }
}
