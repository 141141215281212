import { createSelector } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { selectAppState } from './app.selector';

export const selectPaywallStatus = createSelector(selectAppState, (state: AppState) => {
  return state?.subscriptionModal;
});

/**
 * Selector to check if the store is fully initialized.
 *
 */
export const selectStoreStatus = createSelector(selectAppState, (state: AppState) => {
  return state?.iap?.storeReady;
});

/**
 * Selector to determine if the paywall should be shown.
 * Combines the paywall active status and the store active status.
 *
 */
export const selectShowPaywall = createSelector(
  selectPaywallStatus,
  selectStoreStatus,
  (subModalStatus, storeStatus) => {
    return subModalStatus && storeStatus;
  },
);

export const selectSubscriptionStatus = createSelector(selectAppState, (state: AppState) => {
  return state?.iap?.subscription?.active ?? false;
});

export const selectSubscription = createSelector(selectAppState, (state: AppState) => {
  return state?.iap?.subscription;
});

export const selectRestoringPurchases = createSelector(selectAppState, (state: AppState) => {
  return state?.iap?.restoringPurchases;
});

export const selectIsPurchasing = createSelector(selectAppState, (state: AppState) => {
  return (
    (state?.iap?.subscriptionPurchaseStatus === 'Ordering' ||
      state?.iap?.verifyReceiptStatus == 'Verifying' ||
      (state?.iap?.subscriptionPurchaseStatus === 'Ordered' && state?.iap?.verifyReceiptStatus === 'Initial')) ??
    false
  );
});

export const selectIsOwned = createSelector(selectAppState, (state: AppState) => {
  return (
    (state?.iap?.subscriptionPurchaseStatus === 'Ordered' &&
      state?.iap?.verifyReceiptStatus == 'Verified' &&
      state?.iap?.subscription?.active) ??
    false
  );
});

export const selectIsSubscriptionActive = createSelector(
  selectSubscriptionStatus,
  selectIsOwned,
  (subscriptionStatus, productOwned) => {
    return subscriptionStatus || productOwned;
  },
);
