/* eslint_disable @typescript_eslint/naming_convention */
export type DigiMeEvents =
  /**
   * Event sent when a user initiates a session (visits the app). If the user does not have a distinct_id from a previous session recognized via cookie, the app will assign a new random id to the user and make sure it is sent with all subsequent Mixpanel events for this user.
   */
  | 'user_visit'

  /**
   * Event sent when a user is loaded successfully.
   */
  | 'user_success'

  /**
   * Event sent when a user must onboard.
   */
  | 'source_account_not_loaded'

  /**
  Event sent when the user begins the flow for onboarding a new data source.
  */
  | 'source_add'

  /**
   * Event sent when the user chooses a specific data source to add to their library such as a particular GP, or hospital.
   */
  | 'source_choose'

  /**
   * Event sent just before the user is redirected to authenticate and authorize data sharing with digi.me via the data source. Initially, this represents a Dutch MedMij onboarding, but this may expand to cover England NHS, or US or other providers.
   */
  | 'source_auth_start'

  /**
   * Event sent upon successful onboarding of a data source.
   */
  | 'source_auth_success'

  /**
   * Event sent if the user cancels the auth flow with the provider and comes back to the app. It will not be possible to send this event if the user is never returned from the redirect URL.
   */
  | 'source_auth_fail'

  /**
   * Event sent just before the app kicks off the sync process via the digi.me SDK.
   */
  | 'source_sync_start'

  /**
   * Event sent when the onStatusChange callback is invoked by the digi.me SDK.
   */
  | 'source_sync_update'

  /**
   * Event sent on successful completion of the data source sync process.
   */
  | 'source_sync_success'

  /**
   * Event sent when there is an error during the sync process.
   */
  | 'source_sync_error'

  /**
   * Event sent when a user is show a screen with their data.
   */
  | 'data_view'

  /**
   * Event sent when getting data from the user’s digi.me data library without invoking a sync event. For example, the user has just signed in to the app and already has data in their library which the app is retrieving to show, but the app is not requesting that digi.me perform a new sync to refresh the data from the source.
   */
  | 'data_read'

  /**
   * Event sent when the app requests the digi.me back end to re_sync a data source to see if there is any new data at the source.
   */
  | 'data_resync'

  /**
   * Event sent when the app requests the digi.me back end to delete a user library by calling the SDK’s deleteUser method.
   */
  | 'data_delete'

  /**
   * Event sent when the user begins the account sign-up process.
   */
  | 'sign_up_start'

  /**
   * If possible to intercept the various stages of account sign-up via Azure AD B2C, event sent when user provides an email address.
   */
  | 'sign_up_enter_email'

  /**
   * If possible to intercept the various stages of account sign-up via Azure AD B2C, event sent when user verifies an email address.
   */
  | 'sign_up_verify_email'

  /**
   * If possible to intercept the various stages of account sign-up via Azure AD B2C, event sent when user creates a password.
   */
  | 'sign_up_choose_pwd'

  /**
   * If possible to intercept the various stages of account sign-up via Azure AD B2C, event sent when user provides a phone number for 2FA.
   */
  | 'sign_up_enter_phone'

  /**
   * If possible to intercept the various stages of account sign-up via Azure AD B2C, event sent when user verifies a phone number for 2FA.
   */
  | 'sign_up_verify_phone'

  /**
   * Event sent when the user successfully completes the account sign-up process.
   */
  | 'sign_up_success'

  /**
   * Event sent if the user fails to complete the sign-up process assuming they are directed back to the application or that we are able to get a signal that they did not complete sign_up.
   */
  | 'sign_up_fail'

  /**
   * Event sent when user begins sign_in flow.
   */
  | 'sign_in_start'

  /**
   * Event sent when user enters email and password successfully.
   */
  | 'sign_in_email_and_pwd'

  /**
   * Event sent when user enters their 2FA code successfully.
   */
  | 'sign_in_2fa'

  /**
   * Event sent when user completes sign_in flow.
   */
  | 'sign_in_success'

  /**
   * Event sent if a user fails to complete the sign_in flow and we are aware of it (the user has returned to the app and not abandoned on screens not controlled by the app).
   */
  | 'sign_in_fail'

  /**
   * Event sent when the user chooses to sign out of the app by clicking the sign out / log out button or is signed out by the app for other reasons such as session expiry.
   */
  | 'sign_out'

  /**
   * When a user session expires (not when a user manually signs out)
   */
  | 'session_expired'

  /**
   * Event sent when the user chooses to DELETE their app account.
   */
  | 'user_delete'

  /**
   * Event sent when the chron job for cleaning up incomplete or expired user accounts begins.
   */
  | 'chron_start'

  /**
   * Event sent when the chron job deletes a digi.me library because there is no account associated with it or the account set_up is not complete AND a specified amount of time has passed since creation _ initially 24 hrs.
   */
  | 'chron_delete_library'

  /**
   * Event sent when the chron job deletes a digi.me user account where set_up is not complete AND a specified amount of time has passed since creation _ initially 24 hrs
   */
  | 'chron_delete_user'

  /**
   * Event sent when the chron job completes its check for orphaned libraries and incomplete accounts older than a specified amount of time _ initially 24 hrs.
   */
  | 'chron_end'

  /**
   * Event sent when the throw error to App
   */
  | 'error'

  /**
   * Event sent when reading the file list before getting data from the user’s WDX data library without invoking a sync event.
   */
  | 'data_read_started'

  /**
   *
   * When the files from the file list are loaded
   */
  | 'data_read_success'

  /**
   *
   * When the loading the file-list files combination times out
   */
  | 'data_read_timeout'

  /**
   *  Event sent when the app requests the digi.me back end to refresh data (sourcefetch = true)
   * */
  | 'data_refresh_started'

  /**
   * When an account is read from the get accounts method - dispatch one event per account (source).
   */
  | 'source_account_loaded'

  /**
   * Event sent if a user chooses to abandons the account creation process by clicking “cancel”.
   */
  | 'sign_up_abandoned'

  /**
   * When a download of a document is triggered. Which button the download is started from big or small button.
   */
  | 'document_open_started'

  /**
   * When a download of a document succeeds.
   */
  | 'document_open_success'

  /**
   * When a download of a document fails.
   */
  | 'document_open_failure'
  /**
   * When a patient id is successfully created and stored in AD account for use with self measurements
   */
  | 'patient_id_create_success'
  /**
   * When something goes wrong during create or storing the patient id for use with self measurements
   */
  | 'patient_id_create_failure'
  /**
   * When a record is added and saved successfully to a user’s library.
   */
  | 'record_add_success'
  /**
   * When a record is added but something goes wrong.
   */
  | 'record_add_failure'
  /**
   * When a record is successfully saved to a user’s device as JSON.
   */
  | 'record_download_success'
  /**
   * When a record save to a user’s device starts but something goes wrong.
   */
  | 'record_download_failure'
  /**
   * When a user successfully deletes a record.
   */
  | 'record_delete_success'
  /**
   * When a user initiates deleting a record but something goes wrong.
   */
  | 'record_delete_failure'
  /**
   * When a user selects to view their patient summary in the app.
   */
  | 'patient_summary_view_started'
  /**
   * When patient summary is created successfully and shown in the app.
   */
  | 'patient_summary_view_success'
  /**
   * When patient summary fails to generate and get shown in the app.
   */
  | 'patient_summary_view_failure'
  /**
   * When a user selects to export their patient summary in the app.
   */
  | 'patient_summary_export_started'
  /**
   * When patient summary export is successfully generated and downloaded from the app.
   */
  | 'patient_summary_export_success'
  /**
   * When patient summary export fails to generate or download from the app.
   */
  | 'patient_summary_export_failure'
  /**
   * Shared record success.
   */
  | 'record_share_success'
  /**
   * Shared record failed.
   */
  | 'record_share_failure'
  /**
   * When user dismisses notification.
   */
  | 'notification_receipt'

  /**
   * IAP paywall is viewed.
   */
  | 'iap_paywall_view_opened'
  /**
   * IAP paywall is closed.
   */
  | 'iap_paywall_view_closed'
  /**
   * IAP plugin load has started.
   */
  | 'iap_plugin_load_start'
  /**
   * IAP plugin load has succeeded.
   */
  | 'iap_plugin_load_success'
  /**
   * IAP plugin load has failed.
   */
  | 'iap_plugin_load_failure'
  /**
   * Purchase of product offer has started.
   */
  | 'iap_product_purchase_start'
  /**
   * Purchase of product offer has succeeded.
   */
  | 'iap_product_purchase_success'
  /**
   * Purchase of product offer has failed.
   */
  | 'iap_product_purchase_failure'
  /**
   * IAP transaction has started.
   */
  | 'iap_transaction_start'
  /**
   * IAP transaction is pending.
   */
  | 'iap_transaction_pending'
  /**
   * IAP transaction has been finished.
   */
  | 'iap_transaction_finished'

  /**
   * IAP transaction has succeeded.
   */
  | 'iap_transaction_approve_success'
  /**
   * Receipt validation for IAP has started.
   */
  | 'iap_receipt_validation_start'
  /**
   * Receipt validation for IAP has succeeded.
   */
  | 'iap_receipt_validation_success'
  /**
   * Receipt validation for IAP has failed.
   */
  | 'iap_receipt_validation_failure'

  /**
   * Restore purchase has started.
   */
  | 'iap_restore_purchases_start'
  /**
   * Restore purchase has succeeded.
   */
  | 'iap_restore_purchases_success'

  /**
   * Restore purchase has failed.
   */
  | 'iap_restore_purchases_failure'
  /**
   * Product status has been updated.
   */
  | 'product_status_updated';

export enum ErrorOrigin {
  source = 'source',
  api = 'api',
  sdk = 'sdk',
  app = 'app',
}

export enum Platform {
  web = 'web',
  iOS = 'ios',
  android = 'android',
  windows = 'windows',
  macOS = 'macOs',
}

export enum MeasurementType {
  weight = 'Weight',
  bloodPressure = 'Blood Pressure',
  bloodGlucose = 'Blood Glucose',
  height = 'Height',
  heartRate = 'Heart Rate',
  bodyTemperature = 'Body Temperature',
  o2Saturation = 'O2 Saturation',
  respirationRate = 'Respiration Rate',
}
