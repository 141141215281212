import { SpecimenContainer } from '@hl7fhir';
import { CodeableConceptPipe, getChoiceOfType, IdentifierViewModel, QuantityTypePipe } from '@hl7fhir/data-types';
import { getReference } from '@hl7fhir/foundation';
import { BackboneElementViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';

export class SpecimenContainerViewModel extends BackboneElementViewModel<SpecimenContainer> {
  private readonly specConR = this.element as r3.SpecimenContainer | r4.SpecimenContainer | r4b.SpecimenContainer;
  private readonly specConR5 = this.element as r5.SpecimenContainer;

  //R3, R4, R4B only
  get identifiers(): IdentifierViewModel[] | undefined {
    return (
      this.specConR?.identifier &&
      this.specConR.identifier?.map((item) => new IdentifierViewModel(item, this.fhirVersion))
    );
  }

  //R3, R4, R4B only
  get description(): string | undefined {
    return this.specConR?.description;
  }

  //R3, R4, R4B only
  get type(): string | undefined {
    return this.specConR?.type && new CodeableConceptPipe().transform(this.specConR.type);
  }

  //R3, R4, R4B only
  get capacity(): string | undefined {
    return this.specConR?.capacity && new QuantityTypePipe().transform(this.specConR.capacity);
  }

  get specimenQuantity(): string | undefined {
    return this.element?.specimenQuantity && new QuantityTypePipe().transform(this.element.specimenQuantity);
  }

  //R3, R4, R4B only
  get additive(): string | undefined {
    return getChoiceOfType({
      codeableConcept: this.specConR.additiveCodeableConcept,
      reference: this.specConR.additiveReference,
    });
  }

  //R5 only
  get device(): string | undefined {
    return this.specConR5?.device && getReference(this.specConR5.device);
  }

  //R5 only
  get location(): string | undefined {
    return this.specConR5?.location && getReference(this.specConR5.location);
  }
}
