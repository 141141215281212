import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as features from '../../features';
import { AppState } from '../app.reducer';

export const selectAppState = createFeatureSelector<AppState>(features.APP);

export const selectHasSessionTimedOut = createSelector(selectAppState, (state: AppState) => {
  return state?.hasSessionTimedOut;
});

export const selectOpenModal = (modal: string) => {
  return createSelector(selectAppState, (state: AppState) => {
    return state?.modal === modal;
  });
};

export const selectToast = createSelector(selectAppState, (state: AppState) => {
  return state?.toasts;
});

export const selectIsAuthenticated = createSelector(selectAppState, (state: AppState) => {
  return state.isAuthenticated;
});

export const selectShowShareProvidersModal = createSelector(selectAppState, (state: AppState) => {
  return state?.shareProviders;
});

export const selectIsAppleHealthSupported = createSelector(selectAppState, (state: AppState) => {
  // For Apple Health we need to:
  // - Support: iOS devices and simulators running iOS 17.0+
  // - Not Support: Mac devices running iPad apps ("Designed for iPad")
  // Detection: Mac devices have 'macintosh' in userAgent but no 'mobile' keyword
  const isRunningOnMac =
    navigator.userAgent.toLowerCase().includes('macintosh') && !navigator.userAgent.toLowerCase().includes('mobile');

  if (!state.deviceInfo) {
    return false;
  }

  const info = state.deviceInfo;
  if (info.platform === 'ios' && !isRunningOnMac) {
    const iosVersion = parseFloat(info.osVersion);
    return iosVersion >= 17.0;
  } else {
    // Hide for non-iOS devices
    return false;
  }
});
