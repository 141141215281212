import { SubscriptionPayload, SubscriptionPurchaseActionPayload, TransactionActionPayload } from '@globals';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const PAYWALL_VIEW_ACTIONS = createActionGroup({
  source: 'Paywall',
  events: {
    Open: emptyProps(),
    Opened: emptyProps(),
    Closed: emptyProps(),
  },
});

export const IAP_ACTIONS = createActionGroup({
  source: 'In App Purchases',
  events: {
    'Plugin Load Start': emptyProps(),
    'Plugin Load Success': emptyProps(),
    'Plugin Load Failure': props<{ errors?: CdvPurchase.IError[] }>(),

    'Subscription Purchase Start': props<{ subscription: SubscriptionPurchaseActionPayload }>(),
    'Subscription Purchase Success': props<{ subscription: SubscriptionPurchaseActionPayload }>(),
    'Subscription Purchase Failure': props<{ subscription: SubscriptionPurchaseActionPayload }>(),

    'Transaction Start': props<{ transaction: TransactionActionPayload }>(),
    'Transaction Pending': props<{ transaction: TransactionActionPayload }>(),
    'Transaction Approved': props<{ transaction: TransactionActionPayload }>(),
    'Transaction Finished': props<{ transaction: TransactionActionPayload }>(),

    'Receipt Validation Start': props<{ transaction: TransactionActionPayload }>(),
    'Receipt Validation Success': props<{
      receipt: {
        productId: string;
        validationDate: Date;
        transaction: {
          transactionId: string;
          transactionState: CdvPurchase.TransactionState;
        };
      };
    }>(),
    'Receipt Validation Failure': props<{
      receipt: {
        transactionId: string;
        transactionState: CdvPurchase.TransactionState;
        productId: string;
      };
      error: {
        errorMessage: string | undefined;
        errorCode: CdvPurchase.ErrorCode | undefined;
        errorStatus: number | undefined;
      };
    }>(),

    'Restore Purchases Start': emptyProps(),
    'Restore Purchases Success': emptyProps(),
    'Restore Purchases Failure': props<{ error: CdvPurchase.IError }>(),
  },
});

export const SUBSCRIPTION_ACTIONS = createActionGroup({
  source: 'Subscription',
  events: {
    'Subscription Load Failed': props<{ error: any }>(),
    'Subscription Load Finished': props<{ subscription: SubscriptionPayload | null }>(),
    'Update Finished': props<{ subscription: SubscriptionPayload }>(),
  },
});
