<button type="button"
        [routerLink]="[headerConfig?.navigateTo]"
        [attr.aria-label]="headerConfig?.header"
        class="d-flex btn w-100 align-items-center justify-content-between text-decoration-none text-reset rounded nav-link">
  <div class="d-flex align-items-center p-1">
    <svg class="d-inline-block header-icon"
         aria-hidden="true">
      <use [attr.xlink:href]="svgDefaultPath + '#' + headerConfig?.icon"></use>
    </svg>
    <span class="ms-2 fw-bolder my-auto">{{ headerConfig?.header }}</span>
  </div>
  <mat-icon class="mat-size-2"
            [fontIcon]="'chevron_right'"></mat-icon>
</button>